import React from 'react';
import { Outlet } from 'react-router-dom';

const LoginLayout: React.FC = function () {
  return (
    <div>
      <div className="flex flex-col justify-center min-h-screen bg-gray-300">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="p-8 bg-white rounded-lg shadow">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
