import React from 'react';
import { ParentElement } from '../../types';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableDataCell from './TableDataCell';
import TableHead from './TableHead';
import TableHeaderCell from './TableHeaderCell';

interface TableProps extends ParentElement {}

const Table: React.FC<TableProps> = function ({ children }) {
  return (
    <div className="w-full  overflow-y-auto">
      <table className="min-w-full divide-y divide-gray-300">
        { children }
      </table>
    </div>
  );
};

export default Object.assign(Table, {
  THEAD: TableHead,
  TH: TableHeaderCell,
  BODY: TableBody,
  TR: TableRow,
  TD: TableDataCell,
});
