import Client from './Client';
import { MiddlewareBase, MiddlewareClient } from './middleware.types';
import ShopResource from './resources/ShopResource';

export default class Middleware implements MiddlewareBase {
  public client: MiddlewareClient;

  public shopResource: ShopResource;

  constructor(token: string) {
    this.client = new Client(token);
    this.shopResource = new ShopResource(this.client);
  }
}
