import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button/Button';

interface AppLayoutInformationRowProps {
    title: string;
    buttonText?: string | undefined;
    buttonLink?: string | undefined;
}

const AppLayoutInformationRow: React.FC<AppLayoutInformationRowProps> = function ({ title, buttonText, buttonLink }) {
  const navigate = useNavigate();

  return (
    <div
      className="w-full flex flex-row justify-between mb-4"
    >
      <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>

      {buttonText && (
        <Button
          text={buttonText}
          onClick={
                        () => navigate(
                          buttonLink || '/',
                        )
                    }
        />
      )}
    </div>
  );
};

AppLayoutInformationRow.defaultProps = {
  buttonText: undefined,
  buttonLink: undefined,
};

export default AppLayoutInformationRow;
