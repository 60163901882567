import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import Button from '../../../components/button/Button';
import AppLayoutInformationRow from '../../../layouts/AppLayout/components/AppLayoutInformationRow';
import { CreateShopPayload } from '../../../types';
import Middleware from '../../../plugins/middleware/Middleware';
import { ShopResponse } from '../shops.types';

const ShopCreate: React.FC = function () {
  const navigate = useNavigate();
  const auth = useAuth();
  const [name, setName] = useState<string>('');
  const [fashionchequeMerchantID, setFashionchequeMerchantID] = useState<string>('');
  const [fashionchequeApiKey, setFashionchequeApiKey] = useState<string>('');
  const [fashionchequeApiSecret, setFashionchequeApiSecret] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);

  const verifyPayload = (payload: CreateShopPayload): boolean => {
    const validationErrors: string[] = [];
    if (!payload.name.length) {
      validationErrors.push('Name cannot be empty');
    }

    if (!payload.fcMerchantId.length) {
      validationErrors.push('Merchant ID cannot be empty');
    }

    if (!payload.fcApiKey.length) {
      validationErrors.push('API Key cannot be empty');
    }

    if (!payload.fcApiSecret.length) {
      validationErrors.push('API Secret cannot be empty');
    }

    if (validationErrors.length) {
      setErrors(validationErrors);
      return false;
    }

    return true;
  };

  const save = async () => {
    const payload: CreateShopPayload = {
      name,
      fcApiKey: fashionchequeApiKey,
      fcApiSecret: fashionchequeApiSecret,
      fcMerchantId: fashionchequeMerchantID,
      isActive: enabled,
    };

    if (verifyPayload(payload)) {
      new Middleware(auth.user?.access_token!).client.post('/shop', payload).then((res: AxiosResponse<ShopResponse>) => {
        toast.success('Shop created', { autoClose: 2500 });

        sessionStorage.setItem('active_token', JSON.stringify({
          key: res.data.activeToken.key,
          secret: res.data.activeToken.secret,
        }));

        navigate('/shops');
      }, (reason) => {
        toast.error(reason.message.replace('AxiosError: ', ''));
      });
    }
  };

  return (
    <>
      <AppLayoutInformationRow title="Create Shop" />

      <div className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="webshop-name" className="block text-sm font-medium text-gray-700">
                  Webshop Name
                </label>

                <div className="mt-1">
                  <input
                    type="text"
                    name="webshop-name"
                    id="webshop-name"
                    autoComplete="webshop-name"
                    onChange={(event) => setName(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="fc-merchant-id" className="block text-sm font-medium text-gray-700">
                  Fashioncheque Merchant ID
                </label>
                <div className="mt-1">
                  <input
                    id="fc-merchant-id"
                    name="fc-merchant-id"
                    type="text"
                    onChange={(event) => setFashionchequeMerchantID(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="fc-api-key" className="block text-sm font-medium text-gray-700">
                  Fashioncheque Api Key
                </label>
                <div className="mt-1">
                  <input
                    id="fc-api-key"
                    name="fc-api-key"
                    type="text"
                    onChange={(event) => setFashionchequeApiKey(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="fc-api-secret" className="block text-sm font-medium text-gray-700">
                  Fashioncheque Api Secret
                </label>
                <div className="mt-1">
                  <input
                    id="fc-api-secret"
                    name="fc-api-secret"
                    type="text"
                    onChange={(event) => setFashionchequeApiSecret(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="comments"
                      name="comments"
                      type="checkbox"
                      onChange={(event) => setEnabled(!!event.target.value)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">Enabled</label>
                    <p className="text-gray-500">The webshop connection is enabled</p>
                  </div>
                </div>
              </div>

            </div>

            {errors.length > 0 && (
            <div className="mt-6">
              <label htmlFor="errors" className="block font-medium text-red-700">
                The following errors occurred:
              </label>
              <ul>
                {errors.map((value) => (
                  <li key={value} className="ml-4 block text-red-700">
                    {`-  ${value}`}
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => navigate('/shops')}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>

            <Button
              onClick={() => save()}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              text="Save"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopCreate;
