import React, { useState } from 'react';
import {
  FolderIcon,
  MenuIcon,
} from '@heroicons/react/outline';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppLayoutMobileSidebar from './components/AppLayoutMobileSidebar';
import AppLayoutDesktopSidebar from './components/AppLayoutDesktopSidebar';
import { AppLayoutNavigationItem } from './AppLayout.types';

import 'react-toastify/dist/ReactToastify.css';

const AppLayout: React.FC = function () {
  const navigationItems: AppLayoutNavigationItem[] = [
    {
      name: 'Shops',
      href: '/shops',
      icon: FolderIcon,
    },
  ];

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <div>
      <AppLayoutMobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigationItems={navigationItems}
      />

      {/* Static sidebar for desktop */}
      <AppLayoutDesktopSidebar navigationItems={navigationItems} />

      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className="flex-1">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">
              <Outlet />
            </div>
          </div>
        </main>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AppLayout;
