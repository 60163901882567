import React from 'react';
import { ParentElement, StylableElement } from '../../types';
import classNames from '../../utils/classNames';

interface TableHeaderCellProps extends ParentElement, StylableElement {
}

const TableDataCell: React.FC<TableHeaderCellProps> = function ({
  children, className, isFirst,
}) {
  return (
    <td
      className={
                classNames(
                  isFirst ? ' pl-4 sm:pl-6 lg:pl-8 pr-3' : 'px-3',
                  'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                  className,
                )
            }
    >
      {children}
    </td>
  );
};

export default TableDataCell;
