import React, { useEffect, useState } from 'react';
import {
  Outlet, useNavigate,
} from 'react-router-dom';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useAuth } from 'react-oidc-context';
import { AxiosResponse } from 'axios';
import Table from '../../../components/table/Table';
import { Shop } from '../../../types';
import Button from '../../../components/button/Button';
import AppLayoutInformationRow from '../../../layouts/AppLayout/components/AppLayoutInformationRow';
import Middleware from '../../../plugins/middleware/Middleware';
import { ShopResponse, Token } from '../shops.types';
import TokenModal from './TokenModal';
import TokenRegenerateModal from './TokenRegenerateModal';

export const TOKEN_KEY = 'active_token';

const ShopList: React.FC = function () {
  const navigate = useNavigate();
  const sessionToken = sessionStorage.getItem(TOKEN_KEY);
  const [token, setToken] = useState<Token | null>(null);
  const [shops, setShops] = useState<Shop[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(token !== undefined);
  const [isRegenerateModelOpen, setRegenerateModelIsOpen] = useState<boolean>(false);
  const auth = useAuth();
  const [regenerateTokenShopID, setRegenerateTokenShopID] = useState<string | null>(null);

  useEffect(() => {
    if (!sessionToken) {
      return;
    }

    setToken(JSON.parse(sessionToken) as Token);
  }, [sessionToken]);

  const getShopList = () => {
    new Middleware(auth.user?.access_token!).client.get('/shop/').then((response) => {
      setShops(response.data);
    });
  };

  const regenerateTokens = () => {
    setRegenerateModelIsOpen(false);

    new Middleware(auth.user?.access_token!).client.get(`/shop/${regenerateTokenShopID}/regenerate_tokens`).then((response: AxiosResponse<ShopResponse>) => {
      setToken(response.data.activeToken);
      setRegenerateTokenShopID(null);
    });
  };

  useEffect(getShopList, []);

  useEffect(() => {
    if (token) {
      setIsOpen(true);
    }
  }, [token]);

  function closeModal() {
    setIsOpen(false);
    sessionStorage.removeItem(TOKEN_KEY);
  }

  function openRegenerateModal(shopID?: string) {
    setRegenerateTokenShopID(shopID as string);
    setRegenerateModelIsOpen(true);
  }

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <TokenRegenerateModal isOpen={isRegenerateModelOpen} onClose={regenerateTokens} />

      {/* eslint-disable-next-line react/jsx-no-bind */}
      {token && <TokenModal isOpen={isOpen} token={token} onClose={closeModal} />}

      <AppLayoutInformationRow title="Shops" buttonText="Add Shop" buttonLink="/shops/create" />

      <div className="w-full">
        <Table>
          <Table.THEAD>
            <Table.TH>
              Webshop Name
            </Table.TH>

            <Table.TH>
              Merchant Id
            </Table.TH>

            <Table.TH>
              Active
            </Table.TH>

            <Table.TH>
              {' '}
            </Table.TH>
          </Table.THEAD>

          <Table.BODY>
            {shops.map((shop) => (
              <Table.TR key={shop.id}>
                <Table.TD>
                  {shop.name}
                </Table.TD>

                <Table.TD>
                  <span>
                    {shop.fcMerchantId}
                  </span>
                </Table.TD>

                <Table.TD>
                  {shop.isActive ? <CheckIcon className="h-6 w-6 text-green-700" />
                    : <XIcon className="h-6 w-6 text-red-700" />}
                </Table.TD>

                <Table.TD
                  className="space-x-2 flex flex-wrap justify-end"
                >
                  <Button
                    onClick={() => navigate(`${shop.id}`)}
                    text="Edit"
                  />
                  <Button
                    onClick={() => openRegenerateModal(shop.id)}
                    text="Regenerate"
                  />
                </Table.TD>
              </Table.TR>
            ))}
          </Table.BODY>
        </Table>
        <Outlet />
      </div>
    </>
  );
};

export default ShopList;
