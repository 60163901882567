import React from 'react';
import { ParentElement, StylableElement } from '../../types';
import classNames from '../../utils/classNames';

interface TableHeadProps extends ParentElement, StylableElement{}

const TableHead: React.FC<TableHeadProps> = function ({ children, className }) {
  return (
    <thead
      className={
                classNames(
                  'bg-gray-50',
                  className,
                )
            }
    >
      <tr>
        {children}
      </tr>
    </thead>
  );
};

export default TableHead;
