import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppLayoutInformationRow from '../../../layouts/AppLayout/components/AppLayoutInformationRow';
import Button from '../../../components/button/Button';

import { UpdateShopPayload } from '../../../types';
import Middleware from '../../../plugins/middleware/Middleware';

const ShopEdit: React.FC = function () {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [originalName, setOriginalName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [fashionchequeMerchantID, setFashionchequeMerchantID] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const auth = useAuth();

  const verifyPayload = (payload: UpdateShopPayload): boolean => {
    const validationErrors: string[] = [];
    if (!payload.name.length) {
      validationErrors.push('Name cannot be empty');
    }

    if (!payload.fcMerchantId.length) {
      validationErrors.push('Merchant ID cannot be empty');
    }

    if (validationErrors.length) {
      setErrors(validationErrors);
      return false;
    }

    return true;
  };

  const update = async () => {
    const payload: UpdateShopPayload = {
      name,
      fcMerchantId: fashionchequeMerchantID,
      isActive: enabled,
    };

    if (verifyPayload(payload)) {
      new Middleware(auth.user?.access_token!).client.put(`/shop/${uuid}`, payload).then(() => {
        setOriginalName(name);
        toast.success('Shop updated', { autoClose: 2500 });
      }, (reason) => {
        toast.error(reason.message.replace('AxiosError: ', ''));
      });
    }
  };

  const getShop = () => {
    new Middleware(auth.user?.access_token!).client.get(`/shop/${uuid}`).then((response) => {
      setOriginalName(response.data.name);
      setName(response.data.name);
      setFashionchequeMerchantID(response.data.fcMerchantId);
      setEnabled(response.data.isActive);
    }, (reason) => {
      toast.error(reason.message.replace('AxiosError: ', ''));
    });
  };

  useEffect(getShop, []);

  return (
    <>
      <AppLayoutInformationRow title={`Edit Shop - ${originalName}`} />

      <div className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">General Information</h3>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="webshop-name" className="block text-sm font-medium text-gray-700">
                  Webshop Name
                </label>

                <div className="mt-1">
                  <input
                    type="text"
                    name="webshop-name"
                    id="webshop-name"
                    autoComplete="webshop-name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="fc-merchant-id" className="block text-sm font-medium text-gray-700">
                  Fashioncheque Merchant ID
                </label>
                <div className="mt-1">
                  <input
                    id="fc-merchant-id"
                    name="fc-merchant-id"
                    type="text"
                    value={fashionchequeMerchantID}
                    onChange={(event) => setFashionchequeMerchantID(event.target.value)}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="webshop-enabled"
                      name="webshop-enabled"
                      type="checkbox"
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">Enabled</label>
                    <p className="text-gray-500">The webshop connection is enabled</p>
                  </div>
                </div>
              </div>

            </div>

            {errors.length > 0 && (
            <div className="mt-6">
              <label htmlFor="errors" className="block font-medium text-red-700">
                The following errors occurred:
              </label>
              <ul>
                {errors.map((value) => (
                  <li key={value} className="ml-4 block text-red-700">
                    {`-  ${value}`}
                  </li>
                ))}
              </ul>
            </div>
            )}

          </div>

        </div>

        <div className="pt-5">
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => navigate('/shops')}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>

            <Button
              onClick={() => update()}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              text="Save"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopEdit;
