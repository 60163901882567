import React, {
  createContext, Dispatch, useMemo, useState,
} from 'react';
import { ParentElement } from '../types';

interface LayoutButton {
  text: string;
  url: string;
}

interface LayoutContextProps {
  title: string | null;
  setTitle: Dispatch<React.SetStateAction<string | null>>;
  layoutButton: LayoutButton | null;
  setLayoutButton: Dispatch<React.SetStateAction<LayoutButton | null>>;
}

export const LayoutContext = createContext({} as LayoutContextProps);

const LayoutProvider: React.FC<ParentElement> = function ({ children }) {
  const [title, setTitle] = useState<string|null>(null);
  const [layoutButton, setLayoutButton] = useState<LayoutButton | null>(null);

  const contextValue = useMemo(() => ({
    title,
    setTitle,
    layoutButton,
    setLayoutButton,
  }), [title, layoutButton]);

  return (
    <LayoutContext.Provider
      value={contextValue}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
