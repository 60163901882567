import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { MiddlewareClient } from './middleware.types';
import { REACT_APP_MIDDLEWARE_ENDPOINT } from '../../config';

export default class Client implements MiddlewareClient {
  public client: AxiosInstance;

  constructor(token: string) {
    this.client = axios.create({
      baseURL: REACT_APP_MIDDLEWARE_ENDPOINT,
      headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': true, Authorization: `Bearer ${token}` },
    });
  }

  async get(path: string, params?: object): Promise<AxiosResponse> {
    try {
      return await this.client.get(path, params);
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async post(path: string, payload?: object): Promise<AxiosResponse> {
    try {
      return await this.client.post(path, payload);
    } catch (e: any) {
      throw new Error(e);
    }
  }

  async put(path:string, payload?: object): Promise<AxiosResponse> {
    try {
      return await this.client.put(path, payload);
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
