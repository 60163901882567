import React from 'react';
import { ParentElement, StylableElement } from '../../types';
import classNames from '../../utils/classNames';

interface TableBodyProps extends ParentElement, StylableElement {
}

const TableBody: React.FC<TableBodyProps> = function ({ children, className }) {
  return (
    <tbody className={
            classNames(
              'divide-y divide-gray-200 bg-white',
              className,
            )
        }
    >
      {children}
    </tbody>
  );
};

export default TableBody;
