import React from 'react';

interface LogoProps {

}

const Logo: React.FC<LogoProps> = function () {
  return (
    <img
      className="h-8 w-auto"
      src="/logo.svg"
      alt="fashioncheque logo"
    />
  );
};

export default Logo;
