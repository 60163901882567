import React from 'react';
import { useAuth } from 'react-oidc-context';
import Logo from '../../components/logo/Logo';
import Button from '../../components/button/Button';

const Login: React.FC = function () {
  const {
    signinRedirect,
  } = useAuth();

  return (
    <div>
      <div className="flex flex-col space-y-6">
        <Logo />
        <span className="text-sm font-medium text-gray-700">
          E-commerce dashboard
        </span>
        <Button
          onClick={() => signinRedirect()}
          text="Login"
        />
      </div>
    </div>
  );
};

export default Login;
