import React from 'react';
import { ParentElement, StylableElement } from '../../types';
import classNames from '../../utils/classNames';

interface TableHeaderCellProps extends ParentElement, StylableElement {
}

const TableHeaderCell: React.FC<TableHeaderCellProps> = function ({
  children, className, isFirst,
}) {
  return (
    <th
      scope="col"
      className={
                classNames(
                  isFirst ? ' pl-4 sm:pl-6 lg:pl-8 pr-3' : 'px-3',
                  'py-3.5 text-left text-sm font-semibold text-gray-900',
                  className,
                )
            }
    >
      {children}
    </th>
  );
};

export default TableHeaderCell;
