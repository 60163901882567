import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { AuthProvider, AuthProviderProps, useAuth } from 'react-oidc-context';
import ShopList from './pages/Shops/List/ShopList';
import AppLayout from './layouts/AppLayout/AppLayout';
import ShopCreate from './pages/Shops/Create/ShopCreate';
import LayoutProvider from './providers/LayoutProvider';
import ShopEdit from './pages/Shops/Edit/ShopEdit';
import LoginLayout from './layouts/LoginLayout/LoginLayout';
import Login from './pages/Login/Login';
import {
  REACT_APP_MIDDLEWARE_ENDPOINT,
  REACT_APP_OAUTH_CLIENT_ID,
  REACT_APP_OAUTH_CLIENT_SCOPES,
} from './config';

// eslint-disable-next-line react/function-component-definition
const ProtectedRoute: React.FC = () => {
  const { isLoading, user } = useAuth();
  if (isLoading) {
    return <div>Loading..</div>;
  }
  return user ? <Outlet /> : <Navigate to="/login" />;
};

const App: React.FC = function () {
  const oidcConfig: AuthProviderProps = {
    redirect_uri: window.location.origin,
    authority: REACT_APP_MIDDLEWARE_ENDPOINT,
    client_id: REACT_APP_OAUTH_CLIENT_ID,
    scope: REACT_APP_OAUTH_CLIENT_SCOPES,
    loadUserInfo: false,
    automaticSilentRenew: false,
    onSigninCallback(user) {
      if (user) {
        window.location.href = window.location.origin;
      }
    },
  };

  return (
    <Router>
      <LayoutProvider>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <AuthProvider {...oidcConfig}>
          <Routes>
            <Route path="/login" element={<LoginLayout />}>
              <Route index element={<Login />} />
            </Route>
            <Route path="/" element={(<ProtectedRoute />)}>
              <Route path="/" element={(<AppLayout />)}>
                <Route path="shops/" element={<ShopList />} />
                <Route path="shops/create" element={<ShopCreate />} />
                <Route path="shops/:uuid" element={<ShopEdit />} />
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </LayoutProvider>
    </Router>
  );
};

export default App;
