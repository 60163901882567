import React, { ElementType } from 'react';
import { StylableElement } from '../../types';
import classNames from '../../utils/classNames';

interface ButtonProps extends StylableElement {
    text: string;
    as?: ElementType;
    onClick:
    // eslint-disable-next-line no-unused-vars
        | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
        | undefined;
}

const Button: React.FC<ButtonProps> = function ({ as, text, onClick }) {
  const Tag = as;
  return (
    <Tag
      onClick={onClick}
      className={
        classNames(
          'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        )
    }
    >
      {text}
    </Tag>
  );
};

Button.defaultProps = {
  as: 'button',
};

export default Button;
