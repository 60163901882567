import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppLayoutNavigationItem } from '../AppLayout.types';
import classNames from '../../../utils/classNames';
import Logo from '../../../components/logo/Logo';

interface AppLayoutDesktopSidebarProps {
  navigationItems: AppLayoutNavigationItem[];
}

const AppLayoutDesktopSidebar: React.FC<AppLayoutDesktopSidebarProps> = function ({ navigationItems }) {
  const navigate = useNavigate();

  const doLogout = () => {
    // logout({ localOnly: true });
    navigate('/login');
  };

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <Logo />
          </div>

          <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
            {navigationItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  true ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                )}
              >
                <item.icon
                  className={classNames(
                    true ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  )}
                  aria-hidden="true"
                />

                {item.name}
              </Link>
            ))}
          </nav>
        </div>
        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
          <div className="flex-shrink-0 w-full group block">
            <div className="flex items-center">
              <div>
                {/* <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Patrick Dokter</p> */}
                <button type="button" onClick={() => doLogout()} className="text-md font-medium text-gray-500 group-hover:text-gray-700">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayoutDesktopSidebar;
